import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter02 from '../common/src/assets/image/charity/specter/kapitola_02_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
        <SEO title="Druhá kapitola: Európske začiatky komunizmu | Ako duch komunizmu vládne nášmu svetu"
             description="Druhá kapitola: Európske začiatky komunizmu"
             image={imageChapter02}/>
        <h1>Druhá kapitola: Európske začiatky komunizmu</h1>
      <h2>Úvod</h2>
      <p>Mnohé proroctvá, ktoré boli predpovedané v ortodoxných náboženstvách, sa naplnili. Potvrdili sa aj predpovede Nostradama a proroctvá odovzdávané v kultúrach po celom svete, od Peru až po Kóreu. Prekvapivo presné prorocké texty nájdeme tiež v čínskych dejinách od dynastie Chan až po dynastiu Ming.</p>
      <p>Tieto proroctvá nám hovoria dôležitú pravdu, že história nie je náhodný proces, ale dráma, v ktorej bol priebeh hlavných udalostí už dopredu určený. V&nbsp;období konca času, ktoré by tiež mohlo ohlasovať začiatok nového historického obdobia, očakávajú všetky náboženstvá sveta jedinú vec: príchod Stvoriteľa do ľudského sveta.</p>
      <p>Všetky drámy majú svoje vyvrcholenie. Hoci diabol zosnoval plány na zničenie ľudstva, všemocný Stvoriteľ má svoje vlastné prostriedky, ako ľudí vo svete prebudiť, pomôcť im uniknúť z diablovho otroctva a ponúknuť im spásu. Záverečný boj medzi dobrom a zlom sa odohráva práve dnes.</p>
      <p>Ortodoxné náboženstvá po celom svete predpovedali, že v čase Stvoriteľovho návratu bude svet zaplavený démonmi, ohavnosťami a zlovestnými udalosťami, pretože ľudstvo stratí svoje morálne zábrany. A presne takýto je dnešný svet.</p>
      <p>Úpadok spoločnosti, v ktorom sa dnes nachádzame, sa chystal už dlho. Začal pred stovkami rokov a vyvrcholil s pomocou hlavnej hnacej sily: ateizmu a klamania ľudstva. Karl Marx vytvoril ideológiu, ktorá zahŕňala podvod vo všetkých jeho podobách, a Vladimír Lenin túto teóriu zaviedol do brutálnej praxe.</p>
      <p>Marx však nebol ateista. Bol Satanistom a stal sa démonom, ktorého poslaním bolo zabrániť človeku na konci čias rozpoznať Stvoriteľa.</p>
      <h2>1. Satanistické diela Karla Marxa</h2>
      <p>Marx vydal za svojho života mnoho kníh, z ktorých najznámejšie sú <em>Komunistický manifest </em>z roku 1848 a trojzväzkový <em>Kapitál</em>, publikovaný medzi rokmi 1867 a 1894. Tieto diela tvoria teoretický základ komunistického hnutia.</p>
      <p>Menej známe je však už to, že Marx počas svojho života obrátil svoju dušu k diablovi a stal sa jeho prostredníkom v ľudskej ríši.</p>
      <p>V mladosti bol Marx oddaným kresťanom. Pevne veril v&nbsp;Boha, až kým nepodľahol svojej&nbsp; démonickej premene.</p>
      <p>Vo svojej ranej básni <em>Volanie človeka v zúfalstve</em> Marx napísal o svojom zámere pomstiť sa Bohu:</p>
      <p><em>„Tak mi Boh vzal všetko, čo mám,</em></p>
      <p><em>V nešťastí a ranách osudu.</em></p>
      <p><em>Všetky jeho svety sú preč a&nbsp;nevrátia sa už!</em></p>
      <p><em>Nezostáva mi nič iné než pomsta!</em></p>
      <p><em>&nbsp;</em></p>
      <p><em>Svojou pomstou budem pyšne spôsobovať škodu,</em></p>
      <p><em>Tej bytosti, tomu Pánovi, ktorý kraľuje nad nami.</em></p>
      <p><em>Svoju silu vytvorím zlátaním toho, čo je slabé,</em></p>
      <p><em>A svoje lepšie ja ponechám bez odmeny!</em></p>
      <p><em>&nbsp;</em></p>
      <p><em>Vybudujem si trón na výšinách,</em></p>
      <p><em>Studený, obrovský bude jeho vrchol.</em></p>
      <p><em>Jeho opevnením – poverčivý strach,</em></p>
      <p><em>Jeho strážcom – najtemnejšie muky.“<a href="#_edn1" id="_ednref1">[1]</a></em></p>
      <p>&nbsp;</p>
      <p>O zmenách, ktoré prežíval, sa Marx zmienil v liste svojmu otcovi z 10. novembra 1837: „Opona spadla, môj svätostánok sa rozpadol. Je potrebné na jeho miesto uviesť nových bohov. Ovládol ma skutočný nepokoj a nebudem schopný upokojiť svoje vzrušenie, kým nebudem vo Vašej drahej prítomnosti.“<a href="#_edn2" id="_ednref2">[2]</a></p>
      <p>V básni <em>Bledá deva</em> lyrickým hlasom prehovára mladá žena, ktorá opustí svoju lásku ku Kristu a čaká ju tragický koniec. Marx napísal:</p>
      <p><em>„Nebo som stratila, viem to veľmi dobre. </em></p>
      <p><em>Moja duša, kedysi verná Bohu, je určená pre peklo.“<a href="#_edn3" id="_ednref3">[3]</a></em></p>
      <p>Marxova rodina si očividne na ňom všimla zmeny. V skoršom liste z 2. marca 1837 mu otec napísal: „Tvoje pokroky, nádej, že jedného dňa uvidím Tvoje meno vo veľkej vážnosti, a Tvoje pozemské dobro, nie sú jedinou túžbou môjho srdca. Hoci sú to sny, ktoré už dlhú dobu prechovávam, môžem Ťa ubezpečiť, že ich naplnenie by ma neurobilo celkom šťastným. Šťastný budem iba potom, pokiaľ Tvoje srdce zostane čisté a bude biť ľudsky a pokiaľ žiaden diabol nebude môcť Tvojmu srdcu vziať vznešenejšie city.“<a href="#_edn4" id="_ednref4">[4]</a></p>
      <p>Jedna z Marxových dcér napísala ako jej a jej sestrám, keď boli malé, otec rozprával veľa rozprávok. Jej najobľúbenejší bol príbeh s mnohými zápletkami o Hansovi Röckleovi, čarodejníkovi, ktorý bol neustále bez peňazí a nakoniec musel predať svoje krásne bábky diablovi.<a href="#_edn5" id="_ednref5">[5]</a> Karl Marx výmenou za svoj vlastný úspech zapredal diablovi svoju vlastnú dušu.</p>
      <p>Ako je zrejmé z predchádzajúcich príkladov, opustenie Boha a spolčovanie sa so Satanom je v Marxovej poézii častou témou. Marx hovorí cez lyrický subjekt v básni „Hráč“:</p>
      <p>&nbsp;</p>
      <p><em>„Vrážam, vrážam neomylne</em></p>
      <p><em>svoju krvavo červenú šabľu do tvojej duše.</em></p>
      <p><em>To umenie Boh nechce, ani mu na myseľ nevkročí,</em></p>
      <p><em>však do mozgu až z čiernych hmiel pekla preskočí.</em></p>
      <p><em>&nbsp;</em></p>
      <p><em>Nech moje srdce očaruje, až celkom zmysly sa zatočia:</em></p>
      <p><em>so Satanom som uzatvoril svoju dohodu.</em></p>
      <p><em>To on mi dá svoje znamenia, čas odpočíta,</em></p>
      <p><em>rýchly a nespútaný tanec smrti už mnou zmieta.“<a href="#_edn6" id="_ednref6">[6]</a></em></p>
      <p>&nbsp;</p>
      <p>V&nbsp;Marxovom životopise spisovateľ Robert Payne napísal, že príbehy, ktoré Marx rozprával, by sa mohli považovať za alegorické znázornenie jeho vlastného života. Zdá sa, že Marx vedome konal v diablovom mene.<a href="#_edn7" id="_ednref7">[7]</a></p>
      <p>Marxova duša sa obrátila k zlu. Vo svojom hneve vnímal Boha ako toho, koho je potrebné zvrhnúť. Americký politický filozof Eric Voegelin napísal: „Marx vedel, že svet bol vytvorený bohom. On však nechcel byť stvorenou existenciou. Nechcel vidieť svet z perspektívy stvoreného. ... Chcel vidieť svet z pohľadu coincidentia oppositorum, teda z pozície Boha.“<a href="#_edn8" id="_ednref8">[8]</a></p>
      <p>Vo svojej básni <em>Ľudská pýcha</em> vyjadril Marx svoju túžbu odtrhnúť sa od boha a byť mu rovný:</p>
      <p><em>„S pohŕdaním hádžem rukavicu</em></p>
      <p><em>rozľahlému svetu do tváre.</em></p>
      <p><em>Tam dole ten obrí kňučiaci trpaslík,</em></p>
      <p><em>nemôže zničiť moju blaženosť. </em></p>
      <p><em>&nbsp;</em></p>
      <p><em>Za Boha som sa prehlásil,</em></p>
      <p><em>rozvalinami zničeného sveta sa triumfálne prechádzam.</em></p>
      <p><em>Každé slovo je skutkom a ohňom,</em></p>
      <p><em>a moja náruč je ako náruč Stvoriteľova.“<a href="#_edn9" id="_ednref9">[9]</a></em></p>
      <p>&nbsp;</p>
      <p>V&nbsp;ďalšom verši napísal: „Túžim sa pomstiť Tomu, Kto kraľuje nad nami.“ „Predstava Boha je ústrednou myšlienkou zvrátenej civilizácie. Musí byť zničená.“<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Krátko po Marxovej smrti o ňom jeho slúžka Helene Demuthová povedala, že keď bol chorý, videla ho ako vykonáva akúsi rituálnu modlitbu pred radom zapálených sviečok. Marx teda očividne veril v nadprirodzené veci.<a href="#_edn11" id="_ednref11">[11]</a></p>
      <p>V priebehu ľudskej histórie veľkí mudrci učili vnímajúce bytosti cestu k osvieteniu a položili základy svetových civilizácií. Ježiš Kristus položil základy kresťanskej civilizácie a múdrosť Lao-c’-ho je základom taoizmu, ktorý je ústredným pilierom čínskej filozofie. V starovekej Indii viedlo Šákjamuniho učenie k založeniu budhizmu. Múdrosť týchto svätcov bola neobyčajná – k svojim porozumeniam prichádzali vďaka tomu, že sa osvietili na ceste duchovnej kultivácie a nie bežným štúdiom.</p>
      <p>Marxove teórie síce obsahovali odkazy na práce predchádzajúcich intelektuálov, v skutočnosti však pochádzali od zlého ducha. V básni <em>O Hegelovi</em> Marx napísal:</p>
      <p><em>„Keďže som dospel k tomu najvyššiemu a najhlbšiemu, </em></p>
      <p><em>Nehanebný som ako Boh, zahalený temnotou ako Boh.“<a href="#_edn12" id="_ednref12">[12]</a></em></p>
      <p>Podľa plánu zlého ducha vstúpil Marx do ľudského sveta a založil kult komunizmu, aby poškodil ľudskú morálku a zapríčinil, že sa ľudstvo odvráti od bohov a samé seba odsúdi k večnému utrpeniu v pekle.</p>
      <h2>2. Marxizmus v historickom kontexte</h2>
      <p>Aby sa marxizmus rozšíril, vytvoril duch rôzne intelektuálne a spoločenské základy. Preskúmame ich, keďže slúžili ako kontext pre vzostup marxizmu.</p>
      <p>Podľa znalcov Marxovu teóriu hlboko ovplyvnil Ludwig Feuerbach. Feuerbach ako jeden z prvých popieral Božiu existenciu. Veril, že náboženstvo nie je ničím iným ako pochopením „nekonečnosti vedomia“ – ľudia si teda vymysleli Boha tým, že si predstavili umocnené svoje vlastné schopnosti.<a href="#_edn13" id="_ednref13">[13]</a> Feuerbachova teória vrhá svetlo na to, ako sa komunizmus objavil a rozšíril. Pokroky v oblasti vedy, mechanizácie, hmotného tovaru, medicíny a voľného času vytvorili dojem, že šťastie pochádza z hmotného bohatstva. Preto každá nespokojnosť musí vyplývať zo sociálnych obmedzení. Zdalo sa, že ľudským pokrokom a sociálnymi zmenami ľudia získajú prostriedky k vybudovaniu utópie bez potreby Boha. Táto vízia je hlavným prostriedkom, pomocou ktorého sú ľudia nalákaní a potom zasvätení do kultu komunizmu.</p>
      <p>Feuerbach nebol prvým filozofom, ktorý zavrhol kresťanstvo a Boha. David Friedrich Strauss spochybnil pravosť Biblie a Ježišovo božstvo v knihe <em>Kriticky preskúmaný život Ježiša </em>z roku 1835. Pôvod týchto ateistických myšlienok v histórii sa dá vystopovať späť k obdobiu osvietenstva v 17. a 18. storočí, alebo k obdobiu starovekých Grékov. To však nie je účelom tejto knihy.</p>
      <p>Napriek tomu, že Marxov <em>Komunistický manifest</em> bol napísaný viac ako desať rokov predtým, ako bola zverejnená kniha Charlesa Darwina <em>O pôvode druhov</em>, evolučná teória Marxovi poskytla zdanlivo vedecký základ. Ak sa všetky druhy vyskytujú prirodzene v dôsledku „prirodzeného výberu“ a ľudské bytosti sú len najpokročilejšími organizmami, potom tu nie je priestor pre Boha.</p>
      <p>V decembri roku 1860 napísal Marx o Darwinovej teórii svojmu spolupracovníkovi Friedrichovi Engelsovi, chváliac knihu <em>O pôvode druhov</em> ako „knihu, ktorá obsahuje prírodne historický základ pre tvoj pohľad [historického materializmu].“ V liste adresovanom socialistickému filozofovi Ferdinandovi Lassalleovi v januári roku 1862 Marx napísal: „Darwinova kniha je veľmi dôležitá a slúži mi ako prírodovedecký základ pre triedny boj v dejinách.“<a href="#_edn14" id="_ednref14">[14]</a></p>
      <p>Teória evolúcie v oblasti prírodných vied a materializmus v oblasti filozofie poskytli marxizmu dva mocné nástroje k zavádzaniu ľudí a tiež k získavaniu nových stúpencov.</p>
      <p>Spoločnosť za života Karla Marxa podstúpila hlboké zmeny. V priebehu priemyselnej revolúcie boli remeselné zručnosti nahradené masovou produkciou. Technologický pokrok v poľnohospodárstve vytvoril nadbytočnú pracovnú silu, ktorá sa presťahovala do miest a ťažko pracovala v továrňach. Vďaka voľnému obchodu prišlo k inováciám v oblasti predaja a odbytu. Industrializácia podporila rast miest a voľný pohyb ľudí, informácií a myšlienok.</p>
      <p>Keď bol Marx vykázaný z Nemecka, presťahoval sa do Francúzska, do Belgicka a potom do Anglicka, kde sa usadil v dickensovskom prostredí londýnskych chudobných štvrtí. V neskorších rokoch Marxovho života začala druhá priemyselná revolúcia, ktorá priniesla elektrifikáciu, spaľovací motor a chemickú priemyselnú výrobu. Vynález telegrafu a telefónu znamenal prevrat v komunikácii.</p>
      <p>Ako sa ľudia pokúšali prispôsobiť novej realite technologického pokroku, každá zmena spôsobila v spoločnosti otrasy. Mnohí sa týmto zmenám nedokázali prispôsobiť, čo viedlo k rozdeleniu na majetných a nemajetných, k hospodárskym krízam a podobne. Keďže technológia zároveň umožňovala meniť prirodzený svet vo veľkom rozsahu, arogancia ľudí rástla.</p>
      <p>Tieto spoločenské otrasy vytvorili zrelé podmienky pre šírenie Marxovho pohľadu na spoločenské normy a tradície ako na pozostatky útlaku, ktoré by mali byť zničené. Vplyv Marxizmu preto možno chápať skôr ako súčasť dlhodobého plánu zlého ducha na destabilizáciu ľudstva a pretrhnutie väzieb medzi človekom a Bohom.</p>
      <h2>3. Francúzska revolúcia</h2>
      <p>Francúzska revolúcia z roku 1789 mala obrovský vplyv. Zničila monarchiu, zvrhla tradičný spoločenský poriadok a ustanovila systém „vlády zberby.“</p>
      <p>Engels povedal: „Revolúcia je určite tá najautoritatívnejšia vec, aká existuje; je to čin pri ktorom jedna časť obyvateľstva vnúti svoju vôľu inej časti prostredníctvom pušiek, bajonetov a kanónov – teda autoritárskych prostriedkov a ak nechce víťazná strana bojovať nadarmo, je potom nútená udržovať si svoju vládu silou teroru, ktorý jej zbrane vyvolajú v reakcionároch.“<a href="#_edn15" id="_ednref15">[15]</a></p>
      <p>Klub jakobínov, ktorý sa po francúzskej revolúcii chopil moci, to dobre vedel. Preto potom, čo poslal francúzskeho kráľa Ľudovíta XVI. pod gilotínu, nechal vodca jakobínov Maximilián Robespierre v mene vlády teroru popraviť ďalších 70 000 ľudí, z ktorých väčšina bola celkom nevinná. Neskoršie generácie napísali na Robespierrov hrob tento epitaf:</p>
      <p><em>„Ty, kto prechádzaš okolo, modli sa,</em></p>
      <p><em>Nežiaľ nad tým, že som mŕtvy;</em></p>
      <p><em>Pretože ak by som bol nažive v tento deň,</em></p>
      <p><em>ležal by si tu namiesto mňa!“<a href="#_edn16" id="_ednref16">[16]</a> </em></p>
      <p>V priebehu francúzskej revolúcie praktizovala jakobínska frakcia tri taktiky politického, ekonomického a náboženského teroru, ktoré boli predohrou k tyranii komunistických strán. Francúzski revolucionári založili Revolučný tribunál a v Paríži a na iných miestach postavili gilotíny, ktoré sa stali predchodkyňami politických vrážd za vlády Lenina a Stalina. Revolučné výbory rozhodovali, či bol väzeň vinný, zatiaľ čo špeciálni agenti Národného konventu mali právomoc nad nižšími vojenskými a administratívnymi divíziami. „Sansculoti“, alebo proletariát, mali postavenie najrevolučnejšej triedy.</p>
      <p>Podľa „Zákona 22. Prairialu“, ktorý bol prijatý 10. júna 1794, bolo zakázané prípravné vyšetrovanie a právo na obhajobu a všetky usvedčené osoby museli dostať trest smrti. K vyneseniu rozsudku teraz namiesto dôkazov stačili zvesti, úsudky a osobné názory. Vydanie tohto zákona značne napomohlo vláde teroru. V jeho dôsledku bolo uväznených približne 300 až 500 tisíc podozrivých.<a href="#_edn17" id="_ednref17">[17]</a> Podobne môžeme povedať, že hospodársky teror jakobínov bol úvodom k „vojnovému komunizmu“, ktorý zaviedol Lenin v Rusku. Zákon, ktorý vstúpil do platnosti 26. júla 1793 označil hromadenie zásob za trestný čin, ktorý bolo možné trestať smrťou. Polovojenské jednotky, známe tiež ako armées révolutionnaires (revolučné vojská), boli oprávnené rabovať mestá a dediny a hľadať obilie v domoch, stodolách a skladoch. Tí, ktorí boli obvinení z hromadenia zásob, skončili v rukách besniaceho davu alebo boli poslaní pod gilotínu.<a href="#_edn18" id="_ednref18">[18]</a></p>
      <p>Jednou z najväčších protivníkov francúzskych revolucionárov bola katolícka viera. V priebehu vlády teroru revolucionári vrátane Pierra Gasparda Chaumette založili formu ateizmu, nazývanú „kult rozumu“. Tento kult vychádzal z trendov osvietenstva a mal nahradiť katolicizmus.<a href="#_edn19" id="_ednref19">[19]</a> 5. októbra 1793 zrušil Národný konvent kresťanský kalendár a presadil republikánsky kalendár. 10. novembra bola katedrála Notre-Dame de Paris premenovaná na Chrám rozumu a predmetom uctievania pre masy ľudí sa stala Bohyňa rozumu, stelesnená mladou herečkou. Kult rozumu sa rýchlo presadil po celom Paríži. Behom jedného týždňa zostali v činnosti iba tri kresťanské kostoly. Paríž zaplavil náboženský teror. Kňazi boli hromadne zatýkaní a niektorí boli popravení.<a href="#_edn20" id="_ednref20">[20]</a></p>
      <p>Francúzska revolúcia nielenže poskytla model pre sovietsky režim, ktorý neskôr založil Lenin, ale je tiež úzko spätá s vývojom marxizmu.</p>
      <p>Utopický socialista Francois-Noël Babeuf, ktorý prežil francúzsku revolúciu a bol popravený v roku 1797 za účasť na tzv. „sprisahaní rovných“, obhajoval zrušenie súkromného vlastníctva. Marx považoval Babeufa za prvého revolučného komunistu.</p>
      <p>Francúzsko bolo v 19. storočí silne ovplyvnené socialistickými ideológiami. V Paríži sa rýchlo rozmohol Zväz vyhnancov, ktorý si vzal Babeufa za svojho duchovného zakladateľa. V roku 1835 do Zväzu vyhnancov vstúpil nemecký krajčír Wilhelm Weitling a pod jeho vedením sa táto tajná spoločnosť premenovala na Zväz spravodlivých.</p>
      <p>Na stretnutí, ktoré sa konalo v júni 1847, sa Zväz spravodlivých spojil s Komunistickým korešpondenčným výborom, na čele ktorého stáli Marx a Engels, a spoločne pod vedením týchto dvoch mužov vytvorili Zväz komunistov. Vo februári 1848 vydali Marx a Engels základný dokument medzinárodného komunistického hnutia – <em>Komunistický manifest</em>.</p>
      <p>Po období vlády Napoleona prepukali revolúcie a vzbury jedna za druhou a postihli Španielsko, Grécko, Portugalsko, Nemecko a rôzne časti Talianska, Belgicka a Poľska. V roku 1848 sa v celej Európe rozšírila revolúcia a vojna, čo vytvorilo optimálne prostredie pre šírenie komunizmu.</p>
      <p>Marx a ďalší založili v roku 1864 Medzinárodné robotnícke združenie, známe aj ako Prvá internacionála, a Marx sa stal jeho duchovným vodcom. Ako vodca Prvej internacionály pracoval Marx na vytvorení hlavnej skupiny prísne disciplinovaných revolucionárov, ktorí by zhromaždili robotníkov k povstaniu. Zároveň potreboval vyhostiť z radov organizácie tých, ktorí s ním nesúhlasili. Mikhail Bakunin, prvý veľký ruský marxista, priviedol do komunistického hnutia mnoho ľudí, ale Marx ho obvinil, že je cárskym agentom a vylúčil ho z Prvej internacionály.<a href="#_edn21" id="_ednref21">[21]</a></p>
      <p>V roku 1871 zahájila francúzska pobočka Prvej internacionály prvú komunistickú revolúciu – Parížsku komúnu.</p>
      <h2>4. Začiatky komunizmu v Paríži</h2>
      <p>Parížska komúna vznikla po porážke Francúzska v prusko-francúzskej vojne v roku 1870. Hoci sa francúzsky cisár Napoleon III. vzdal, ešte predtým ako sa pruské vojská stiahli, obliehali Paríž. Poníženie z porážky v kombinácii s dlhoročnými nepokojmi medzi francúzskymi robotníkmi viedlo v Paríži k všeobecnému povstaniu. Novovzniknutá Tretia francúzska republika sa stiahla do Versailles a zanechala v hlavnom meste mocenské vákuum.</p>
      <p>V marci 1871 iniciovala Parížska komúna pod vedením socialistov, komunistov, anarchistov a ďalších aktivistov povstanie ozbrojených davov a lupičov z najnižších vrstiev spoločnosti. Hnutie bolo napojené na Prvú internacionálu, ktorá ho silno ovplyvňovala. Cieľom bolo využiť proletariát ako hybnú silu revolúcie, ktorá mala zničiť tradičnú kultúru a pretvoriť politickú a ekonomickú štruktúru spoločnosti.</p>
      <p>Nasledovalo masové zabíjanie a ničenie, pri ktorom povstalci rozbíjali nádherné pamiatky, monumenty a umenie Paríža. Francúzski spisovatelia Edmond a Jules Goncourtovci si položili rečnícku otázku: „Načo sú mi dobré pamiatky, opery, koncerty a kaviarne, do ktorých som nikdy nevkročil, pretože nemám peniaze?“ Americký diplomat Wickham Hoffman, ktorý bol v Paríži svedkom ničenia, prehlásil: „Je to trpké, neúprosné a kruté. Nepochybne ide o smutný odkaz krvavej revolúcie z roku 1789.“ Americký spisovateľ William Pembroke Fetridge označil komúnu za „revolúciu krvi a násilia“ a „najtrestuhodnejší [zločin], aký svet kedy videl.“ Jeho vodcovia boli „bezcitní podliaci, … odpad Francúzska… šialenci, opití vínom a krvou.“<a href="#_edn22" id="_ednref22">[22]</a></p>
      <p>Boj medzi zástancami a odporcami tradície, ktorý začal v priebehu francúzskej revolúcie, pokračoval o ďalších osemdesiat rokov. Čestný predseda Parížskej komúny Louis-Auguste Blanqui prehlásil: „Vo Francúzsku pôsobia dva princípy: princíp zákonnosti a princíp zvrchovanosti ľudu… Zásada zvrchovanosti ľudu spája všetkých ľudí budúcnosti, masy, ktoré sú unavené tým, že sú vykorisťované, a snažia sa rozbiť štruktúru, ktorá ich dusí.“<a href="#_edn23" id="_ednref23">[23]</a></p>
      <p>Extrémizmus komúny vznikol čiastočne z nenávistných myšlienok Henriho de Saint-Simona, utopického socialistu, ktorý považoval blaho krajiny priamo úmerné počtu jeho robotníkov. Obhajoval smrť bohatých, ktorých považoval za parazitov.</p>
      <p>Marx v diele <em>Občianska vojna vo Francúzsku</em>, opísal komúnu ako komunistický štát: „Komúna bola priamym protikladom cisárstva. Volanie po „sociálnej republike“, ktorým parížsky proletariát zahájil februárovú revolúciu, vyjadrovalo iba neurčitú túžbu po republike, ktorá mala odstrániť nielen monarchickú formu triedneho panstva, ale aj samotné triedne panstvo. Komúna bola určitou formou takejto republiky.“ Napísal tiež, že „Komúna chcela odstrániť triedne vlastníctvo, ktoré premieňa prácu mnohých ľudí v bohatstvo niekoľkých málo ľudí.“<a href="#_edn24" id="_ednref24">[24]</a></p>
      <p>Parížska komúna vykazovala všetky znaky komunistickej revolúcie. Pamätný Vendomský stĺp Napoleona bol zničený. Kostoly boli vyplienené, duchovenstvo zmasakrované a náboženské učenie v školách zakázané. Povstalci obliekli sochy svätých do moderného oblečenia a pripevnili im k ústam fajky.</p>
      <p>Ženy sa tohoto barbarstva zúčastňovali rovnako ako muži. Čínsky diplomat Čang Te-i, ktorý bol v tom čase v Paríži, opísal túto situáciu nasledovne: „Medzi násilníckymi rebelmi neboli iba muži, ženy sa do toho ošiaľu zapájali tiež.. Ubytovali sa vo vysokých budovách a kŕmili sa lahôdkami. Ale ich potešenie bolo krátkodobé, keďže si neuvedomovali nebezpečenstvo, ktoré sa k nim blížilo. Tesne pred svojou úplnou porážkou domy vyrabovali a spálili. Neoceniteľné poklady sa premenili na popol. Boli zatknuté stovky rebeliek, ktoré priznali, že podpaľačské útoky viedli predovšetkým ženy.“<a href="#_edn25" id="_ednref25">[25]</a></p>
      <p>Besniace šialenstvo, ktoré sprevádzalo pád Parížskej komúny, nie je prekvapujúce. Dňa 23. mája 1871, predtým než padla posledná obranná línia, vedenie komúny nariadilo vypáliť Luxemburský palác (sídlo francúzskeho senátu), Tuilerijský palác a Louvre. Parížska opera, radnica, ministerstvo vnútra, ministerstvo spravodlivosti, kráľovský palác, luxusné reštaurácie a byty na oboch stranách Champs-Elysées mali byť tiež radšej zničené, aby sa nedostali do rúk vlády.</p>
      <p>O siedmej hodine večer dopravili členovia komúny decht, asfalt a terpentín do viacerých miest po celom Paríži a zapálili požiare. Veľkolepý Tuilerijský palác zmizol v plameňoch. Pokus podpaľačov zapáliť neďaleký Louvre bol našťastie zmarený príchodom vojakov pod vedením Adolfa Thiersa.<a href="#_edn26" id="_ednref26">[26]</a></p>
      <p>V nadväznosti na Parížsku komúnu Marx svoju teóriu urýchlene upravil. V Komunistickom manifeste urobil zmenu, ktorá sa týkala toho, že robotnícka trieda by nemala štátny aparát jednoducho prevziať, ale mala by ho celkom rozbiť a zničiť.</p>
      <h2>5. Najskôr Európa, potom celý svet</h2>
      <p>Marxov upravený manifest dodal komunizmu ešte deštruktívnejšiu povahu a ešte väčší vplyv. 14. júla roku 1889, šesť rokov po Marxovej smrti, trinásť rokov po rozpade Prvej Internacionály a sto rokov po francúzskej revolúcii, bolo obnovené Medzinárodné robotnícke združenie. Marxisti sa opäť zjednotili a vytvorili skupinu, ktorú historici označujú ako Druhú Internacionálu.</p>
      <p>Európske robotnícke hnutie, vedené komunistickými sloganmi ako „osloboďme ľudstvo“ alebo „zrušme spoločenské triedy“, sa rýchlo rozmáhalo. Lenin neskôr povedal: „Služba, ktorú Marx a Engels preukázali robotníckej triede, môže byť vyjadrená niekoľkými slovami takto: Naučili robotnícku triedu, aby spoznala a uvedomila si samú seba a nahradila sny vedou.“<a href="#_edn27" id="_ednref27">[27]</a></p>
      <p>Historik Eric Hobsbawm v diele <em>Ako zmeniť svet: Úvahy o Marxovi a marxizme</em> píše: „Sálanie marxizmu bolo obzvlášť významné a všeobecné v niektorých európskych krajinách, kde bolo prakticky všetko spoločenské myslenie, bez ohľadu na to, či bolo politicky prepojené so socialistickým alebo robotníckym hnutím, viditeľne ovplyvnené Marxom.“<a href="#_edn28" id="_ednref28">[28]</a> Ľudové hnutia boli infikované komunistickými ideálmi za použitia lží a indoktrinácie. Komunistickú ideológiu tak prijímalo stále viac ľudí.</p>
      <p>V tej dobe sa komunizmus začal šíriť cez Európu do Ruska a ďalej na východ. Lenin začal v 80. rokoch 19. storočia študovať <em>Kapitál </em>a začal do ruštiny prekladať <em>Komunistický manifest. </em>Úrady cárskeho Ruska ho za jeho politické aktivity nakoniec uväznili a neskôr ho poslali do exilu.</p>
      <p>Prvá svetová vojna viedla k víťazstvu komunizmu v Rusku. V čase revolúcie v roku 1917, ktorá zvrhla cára Mikuláša II., pôsobil Lenin v západnej Európe. Koncom roka sa vrátil späť do Ruska a&nbsp;v&nbsp;priebehu &nbsp;októbrovej revolúcie sa chopil moci. Rusko bolo národom so starobylými tradíciami, obrovskou populáciou a bohatými prírodnými zdrojmi. Zriadenie sovietskeho režimu na území najväčšej krajiny sveta bolo pre svetové komunistické hnutie obrovským prínosom.</p>
      <p>Podobne ako prvá svetová vojna napomohla vzostupu komunistov v Rusku, druhá svetová vojna bola podnetom k tomu, že sa komunistické hnutie rozšírilo cez Euráziu a pohltilo Čínu. Po druhej svetovej vojne sa Sovietsky zväz stal superveľmocou s jadrovými zbraňami, ktorá ovplyvňovala svetové udalosti a podporovala komunizmus po celom svete.</p>
      <p>Winston Churchill povedal: „Na dejisko, len nedávno osvetlené víťazstvom spojencov, padol tieň. Nikto nevie, čo sovietske Rusko a jeho medzinárodná komunistická organizácia zamýšľajú v blízkej budúcnosti urobiť, či aké sú obmedzenia (ak sú vôbec nejaké) ich rozpínavých tendencií a sklonov obracať všetkých na svoju vieru.“<a href="#_edn29" id="_ednref29">[29]</a></p>
      <p>Počas studenej vojny sa slobodný svet zapojil do prudkej konfrontácie s komunistickým táborom, ktorý sa rozprestieral cez štyri kontinenty. Národy slobodného sveta, ktoré boli svojou formou demokratické, sa však vo svojej podstate postupne premenili na socialistické.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Karl Marx, „Invocation of One in Despair“, in <em>Early Works of Karl Marx: Book of Verse</em>, Marxists Internet Archive, stránka navštívená 28. augusta 2019, <a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse11.htm"></a><a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse11.htm">https://www.marxists.org/archive/marx/works/1837-pre/verse/verse11.htm</a>.</p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Karl Marx, „Letter From Marx to His Father in Trier“, in <em>The First Writings of Karl Marx, </em>Marxists Internet Archive, stránka navštívená 28. augusta 2019, <a href="https://www.marxists.org/archive/marx/works/1837-pre/letters/37_11_10.htm"></a><a href="https://www.marxists.org/archive/marx/works/1837-pre/letters/37_11_10.htm">https://www.marxists.org/archive/marx/works/1837-pre/letters/37_11_10.htm</a>.</p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Karl Marx, „The Pale Maiden“, in <em>Early Works of Karl Marx: Book of Verse, </em>Marxists Internet Archive, stránka navštívená 28. augusta 2019, <a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse24.htm"></a><a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse24.htm">https://www.marxists.org/archive/marx/works/1837-pre/verse/verse24.htm</a>.</p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Heinrich Marx, citácia z knihy Richarda Wurmbranda, <em>Marx &amp; Satan</em> (Westchester, Ill.: Crossway Books, 1986), 21.</p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Eleanor Marx-Aveling, „Biographical Notes on Marx’s Literary Interests“, in <em>Marx and Engels on Literature and Art</em>, Marxists Internet Archive, stránka navštívena 18. apríla 2020, <a href="https://marxists.catbull.com/archive/marx/bio/marx/eleanor-literature.htm"></a><a href="https://marxists.catbull.com/archive/marx/bio/marx/eleanor-literature.htm">https://marxists.catbull.com/archive/marx/bio/marx/eleanor-literature.htm</a>.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Karl Marx, „The Fiddler“, in <em>Early Works of Karl Marx: Book of Verse</em>, Marxists Internet Archive, stránka navštívena 28. augusta 2019, <a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse4.htm"></a><a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse4.htm">https://www.marxists.org/archive/marx/works/1837-pre/verse/verse4.htm</a>.</p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Robert Payne, <em>Marx</em> (New York: Simon and Schuster, 1968).</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> Eric Voegelin, <em>From Enlightenment to Revolution</em>, ed. John H. Hallowell (Durham, NC: Duke University Press, 1975), 298–299.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Karl Marx, „Human Pride“, in <em>Early Works of Karl Marx: Book of Verse</em>, Marxists Internet Archive, stránka navštívená 28. augusta 2019, <a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse20.htm"></a><a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse20.htm">https://www.marxists.org/archive/marx/works/1837-pre/verse/verse20.htm</a>.</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Marx, citácia z: Wurmbrand, <em>Marx &amp; Satan</em>, 2.</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Wurmbrand, <em>Marx &amp; Satan</em>, 28.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Karl Marx, „On Hegel“, v <em>Early Works of Karl Marx: Book of Verse</em>, Marxists Internet Archive, stránka navštívená 28. augusta 2019, <a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse15.htm"></a><a href="https://www.marxists.org/archive/marx/works/1837-pre/verse/verse15.htm">https://www.marxists.org/archive/marx/works/1837-pre/verse/verse15.htm</a>.</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Ludwig Feuerbach, „Essence of Religion in General“, in <em>The Essence of Christianity</em>, Marxists Internet Archive, stránka navštívená 28. augusta 2019, <a href="https://www.marxists.org/reference/archive/feuerbach/works/essence/ec01_1.htm"></a><a href="https://www.marxists.org/reference/archive/feuerbach/works/essence/ec01_1.htm">https://www.marxists.org/reference/archive/feuerbach/works/essence/ec01_1.htm</a>.</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Karl Marx, citácia z: I. Bernard Cohen, <em>Revolution in Science</em> (Cambridge, Mass.: The Belknap Press of Harvard University Press, 1985), 345.</p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Friedrich Engels, „On Authority“, v <em>The Marx-Engels Reader</em>, Marxists Internet Archive, stránka navštívená 18. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1872/10/authority.htm"></a><a href="https://www.marxists.org/archive/marx/works/1872/10/authority.htm">https://www.marxists.org/archive/marx/works/1872/10/authority.htm</a>.</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> „Robespierre’s Epitaph“, <em>The Tomahawk</em> (9. januára 1796), Romantic Circles, stránka navštívená 6. septembra 2019, <a href="https://www.rc.umd.edu/editions/warpoetry/1796/1796_2.html"></a><a href="https://www.rc.umd.edu/editions/warpoetry/1796/1796_2.html">https://www.rc.umd.edu/editions/warpoetry/1796/1796_2.html</a>.</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> Jacques Godechot, „The Internal History of France During the Wars, 1793–1814“, v <em>The New Cambridge Modern History</em>, ed. C. W. Crawley (Cambridge, UK: Cambridge University Press, 1965), 9:280–281.</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Miguel A. Faria Jr., „The Economic Terror of the French Revolution“, Hacienda Publishing, 1. júla 2003, stránka navštívená 18. apríla 2020, <a href="https://haciendapublishing.com/articles/economic-terror-french-revolution"></a><a href="https://haciendapublishing.com/articles/economic-terror-french-revolution">https://haciendapublishing.com/articles/economic-terror-french-revolution</a>.</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> Gregory Fremont-Barnes, <em>Encyclopedia of the Age of Political Revolutions and New Ideologies, 1760–1815</em> (Westport, CT: Greenwood Press, Inc., 2007), 119.</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> William Henley Jervis, <em>The Gallican Church and the Revolution</em> (London: Kegan Paul, Trench, &amp; Co., 1882).</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> W. Cleon Skousen, „The Founders of Communism“, in <em>The Naked Communist</em> (Salt Lake City, UT: Ensign Publishing Company, 1962).</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> John M. Merriman, <em>Massacre: The Life and Death of the Paris Commune</em> (New York: Basic Books, 2014).</p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> Louis-Auguste Blanqui, „Speech Before the Society of the Friends of the People“, v <em>Selected Works of Louis-Auguste Blanqui</em> (Scotts Valley, CA: CreateSpace Independent Publishing Platform, 2011), 15.</p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> Karl Marx, „The Paris Commune“, v <em>The Civil War in France</em>, Marxists Internet Archive, stránka navštívená 19. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1871/civil-war-france/index.htm"></a><a href="https://www.marxists.org/archive/marx/works/1871/civil-war-france/index.htm">https://www.marxists.org/archive/marx/works/1871/civil-war-france/index.htm</a>.</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> Zhang Deyi 張德彝, <em>San shu qi</em> 三述奇 <em>[The Third Diary]</em>, (Shanghai: Shanghai Guji Chubanshe, 1995). (v čínštine)</p>
      <p><a href="#_ednref26" id="_edn26">[26]</a> Merriman, <em>Massacre</em>.</p>
      <p><a href="#_ednref27" id="_edn27">[27]</a> Vladimir Lenin, „Frederick Engels“, v <em>Lenin Collected Works</em>, vol. 2 (Moscow: Progress Publishers, 1972), Marxists Internet Archive, stránka navštívená 19. apríla 2020, <a href="https://www.marxists.org/archive/lenin/works/1895/misc/engels-bio.htm"></a><a href="https://www.marxists.org/archive/lenin/works/1895/misc/engels-bio.htm">https://www.marxists.org/archive/lenin/works/1895/misc/engels-bio.htm</a>.</p>
      <p><a href="#_ednref28" id="_edn28">[28]</a> Eric Hobsbawm, <em>How to Change the World: Reflections on Marx and Marxism</em> (New Haven, CT, and London: Yale University Press, 2011), 214.</p>
      <p><a href="#_ednref29" id="_edn29">[29]</a> Winston Churchill, „The Sinews of Peace (,Iron Curtain Speechʻ)“ (predslov, Westminster College, Fulton, MO, 5. marca 1946), International Churchill Society, stránka navštívená 19. apríla 2020, <a href="https://winstonchurchill.org/resources/speeches/1946-1963-elder-statesman/the-sinews-of-peace"></a><a href="https://winstonchurchill.org/resources/speeches/1946-1963-elder-statesman/the-sinews-of-peace">https://winstonchurchill.org/resources/speeches/1946-1963-elder-statesman/the-sinews-of-peace</a>.</p>
      <Link to={`/kapitola_03`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


